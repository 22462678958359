 @import "../../utils/includes.scss";

 //
 // Fonts
 //
 
 $fontFamily: 'KiaSignatureRegular', Arial, sans-serif;
 $boldFontFamily: 'KiaSignatureBold', Arial, sans-serif;
 $boldFontWeight: 400;
 $titleFontFamily: $boldFontFamily;
 $titleFontWeight: $boldFontWeight;
 $isUppercase: uppercase;
 
 //
 // Colors
 //
 
 $textColor: #05141F;
 $linkColor: #05141F;
 $labelColor: #697278;
 $formColor: #05141F;
 $utilColor: #05141F;
 $priceColor: #05141F;
 $lightColor: #FFFFFF;
 $btnColorAlpha: #FFFFFF;
 $btnColorBeta: #05141F;
 $btnColorStep: #FFFFFF;
 $titleColor: #05141F;
 $titleSecondaryColor: #FFFFFF;
 $subtitleColor: #05141F;
 $subtitleSecondaryColor: #FFFFFF;
 $successColor: #5D7D2B;
 $warningColor: #F3C300;
 $errorColor: #EA0029;
 
 $bgPrimaryColor: #ffffff;
 $bgSecondaryColor: #f2f2f2;
 
 $bgBtnAlpha: #05141F;
 $colorBtnAlpha: #FFFFFF;
 $bgBtnBeta: #FFFFFF;
 $colorBtnBeta: #05141F;
 $colorBtnOutlineAlpha: #05141F;
 $colorBtnOutlineBeta: #05141F;
 $paddingButtonPrimary: 15px 40px;
 $paddingButtonSecondary: 10px 15px;
 $borderRadiusButton: 0px;
 
 $borderRadiusForm: 0px;
 $borderColorForm: #05141F;
 $borderSizeForm: 1px solid;
 
 $calculatorTabsColor: #05141F;
 $calculatorTabsTextColor: #FFFFFF;
 $calculatorCloseTabs: #05141F;
 $calculatorCloseTabsColor: #05141F;
 $calculatorMainColor: #FFFFFF;
 $calculatorButton: #05141F;

 $paymentCalculatorClosedBG: #05141F;
 $paymentCalculatorClosedColor: #FFFFFF;
 $paymentCalculatorPanelBG: #05141F;
 $progressBarCloseButtonColor: #FFFFFF;
 $paymentCalculatorTermWrapperBG: #FFFFFF;
 $paymentCalculatorTermWrapperColor: #05141F;
 $paymentCalculatorTermSelectedWrapperBG: #FFFFFF;
 $paymentCalculatorTermSelectedColor: #05141F;
 $paymentCalculatorTermBG: #B8BBBD;
 $paymentCalculatorTermColor: #05141F;
 
 $garageBackground: #FFFFFF;
 $garageTextColor: #05141F;
 $garageCarNoteColor: #FFFFFF;
 
 $bgTopbarLogin: #FFFFFF;
 $colorTopbarLogin: #05141F;
 
 $progressBarTitleBG: #05141F;
 $progressBarTitleColor: #FFFFFF;
 $progressBarTitleCloseBG: #FFFFFF;
 $progressBarTitleCloseColor: #05141F;
 $progressBarCloseButtonBG: #05141F;
 $progressBarCloseButtonColor: #FFFFFF;

// ========
// Styles
// ========
.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__kia{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        /*** Buttons ***/
        .sr-button-1,
        .sr-button-2{
            box-shadow: none !important;
            text-transform: none !important;
            font-size: 14px !important;
            &:hover{
                opacity: 1 !important;
                text-decoration: underline;
                label{
                    text-decoration: underline;
                }
                icon{
                    text-decoration: none;
                }
            }
            &.vehicle-cta-btn{
                icon{
                    display: none;
                }
            }
            &.is-small{
                font-size: 12px !important;
            }
            &.is-uppercase{
                text-transform: none !important;
            }
        }
        .sr-button-2{
            border: 1px solid #05141F !important;
        }
        .sr-button-1.btn-step{
            icon{
                display: none;
            }
        }
        button.button-social-fb.button:hover{
            text-decoration: none;
        }
        .sr-button-outline-2:hover:not(.is-disabled),
        .sr-button-outline-1:hover:not(.is-disabled){
            opacity: 1 !important;
            span{
                text-decoration: underline;
            }
        }
        .sr-button-outline-2:hover{
            span{
                text-decoration: underline;
            }
        }
        .widget-garage .GarageView .add-car-add-vehicle:hover{
            text-decoration: underline;
        }
        /*** Disclaimers ***/
        .sr-disclaimer {
            color: $labelColor !important;
        }

        /*** Barre de login et transaction ***/
        .sr-topbar-login,
        .LoginBanner .LoginBannerWrapper{
            height: 34px;
        }
        .LoginBanner .login-content{
            display: flex;
            align-items: center;
        }

        #button-register.sr-button-1{
            background: #FFFFFF;
            color: #05141F;
            border: 1px solid #05141F;
            &:hover{
                opacity: 1;
                .label{
                    text-decoration: underline;
                }
            }
            .label{
                padding: 0;
                text-transform: none;
                font-size: 12px;
                cursor: pointer;
            }
        }
        #button-login.btn-login{
            .label{
                text-transform: none;
                font-size: 12px;
                cursor: pointer;
            }
            &:hover{
                text-decoration: none !important;
                icon{
                    text-decoration: none !important;
                }
                .label{
                    text-decoration: underline;
                }
            }
        }
        .TransactionSummary--closeButton button{
            border-radius: 0px;
        }
        .TransactionSummaryUnit--single{
            &:nth-child(1){
                background-color: #F8F8F8;
                border-bottom-color: #DDDDDE!important;
                &:before{
                    border-left-color: #F8F8F8!important;
                }
            }
            &:nth-child(2){
                background-color: #DDDDDE;
                border-bottom-color: #B8BBBD!important;
                &:before{
                    border-left-color: #DDDDDE!important;
                }
            }
            &:nth-child(3){
                background-color:#B8BBBD;
                border-bottom-color: #697278!important;
            }
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single{
            &:nth-child(1).is-validate{
                background-color: #697278;
                border-bottom-color: #05141F!important;
                &:before{
                    border-left-color: #697278!important;
                }
            }
            &:nth-child(2).is-validate{
                background-color: #697278;
                border-bottom-color: #05141F!important;
                &:before{
                    border-left-color: #697278!important;
                }
            }
            &:nth-child(3).is-validate{
                background-color:#697278;
                border-bottom-color: #05141F!important;
            }
        }
        .TransactionSummaryUnit--single .validFlag:before{
            background-image: -webkit-linear-gradient(304deg,transparent 50%,#05141F 55%);
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate .wrapper-icon img{
            filter: invert(90%) grayscale(90%);
        }
        .LoginBanner .login-container .dropdown .dropdown-content{
            height: 151px;
            #profile-link.is-light,
            #logout-link.is-light{
                color: #05141F;
            }
        }
        .widget-login .dropdown-content, .widget-register .dropdown-content{
            border-radius: 0px;
            box-shadow: none;
            border: 1px solid #05141F;
        }
        .widget-login-popupElement .SignInForm .ModalHeader .sub-text,
        .widget-register-popupElement .SignUpForm .ModalHeader .sub-text{
            color: #05141F;
        }
        .separator:after, .separator:before{
            background: $labelColor;
        }
        .separator span{
            color: #05141F;
        }
        .sr-modal--white-close{
            color: #666666;
        }
        .TimeSavedBlock--wrapper.has-success-background{
            background-color: #05141F;
        }
        .vehicle-title-specs .vehicle-specs li.is-light{
            color: $labelColor;
        }
        .TimeSavedBlock--wrapper span{
            font-style: normal;
        }
        .TransactionProgressBar--wrapper{
            background-color: #DDDDDE;
        }
        .TransactionProgressBar--progression{
            background-color: #8B9195;
        }
        .widget-garage .GarageView-tab-wrapper{
            border-bottom: 4px solid #05141F;
            padding-bottom: 5px;
        }
        .widget-garage .vehicle-wrapper-info .vehicle-rebate icon.is-price{
            color: #05141F;
        }
        .vehicle-wrapper-image__condition.has-secondary-background{
            background-color: #f8f8f8;
        }
        .widget-garage .vehicle-wrapper-info .vehicle-delete,
        .widget-garage .payment-wrapper .payment-summary:before,
        .widget-garage .garage-vehicle-payments .payment-info{
            color: #05141F;
        }
        .TransactionActionDetails--wrapper .sr-link.delete-trade-in{
            color: #05141F !important;
        }
        .confirm_financing--icon,
        .confirm_deposit--icon{
            border-color: #05141F !important;
            icon{
                color: #05141F !important;
            }
        }
        .confirm_financing--title{
            color: #05141F;
        }
        .confirm_deposit--title{
            color: #05141F !important;
        }

        /*** VLP ***/
        .listing-used{
            background-color: #FFFFFF;
        }
        #catalog{
            background-color: #F2F2F2;
        }
        .listing-tile-note, .listing-tile-note .vehicle-note{
            background: #FFFFFF;
        }
        .listing-tiles-payment .is-light,
        .listing-tile-footer .is-light{
            color: #05141F;
        }
        .listing-tile,
        .vehicle-item .vehicle-info,
        .listing-new-tile .listing-new-tile-wrapper{
            border-radius: 0px;
        }
        .listing-used-bodystyle{
            background: #FFFFFF;
            border-radius: 0px;
            border: 1px solid #B8BBBD;
            color: #05141F;
            &:hover{
                border: 1px solid #05141F;
            }
            &.selected{
                border: 1px solid #05141F;
                background: #05141F;
                color: #FFFFFF;
                &:before{
                    color: #FFFFFF;
                }
            }
        }
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button{
            background: #FFFFFF;
            border-radius: 0px;
            border: 1px solid #B8BBBD;
            color: #05141F;
            font-family: $fontFamily;
            &:hover{
                opacity: 1;
                border: 1px solid #05141F;
            }
            &.selected{
                border: 1px solid #05141F;
                background: #05141F;
                color: #FFFFFF;
            }
        }
        .listing-used-filters .rc-slider .rc-slider-rail,
        .listing-used-filters .rc-slider .rc-slider-step{
            height: 1px;
        }
        .listing-used-filters .rc-slider .rc-slider-track{
            height: 2px;
            background: #05141F;
        }
        .listing-used-filters .rc-slider .rc-slider-handle{
            border: 1px solid #B8BBBD;
            background-color: #FFFFFF !important;
            margin-top: -9px;
        }
        .listing-tile .listing-tile-image .car-tagline{
            color: #ffffff;
        }

        .listing-used-filters-bloc-make .model-list .model-label.selected.is-price{
            font-weight: bold;
        }
        .payment-summary-section .listing-tile-all-price .disclaimer-price{
            font-style: normal;
        }
        .listing-tile .vehicle-certification{
            width: 100%;
        }
        .listing-tile .certification-badge__image{
            max-width: 100% !important;
        }
        .listing-tile .vehicle-certification .certification-badge-secondary .certification-badge__image{
            max-width: 120px !important;
        }
        /** Calculatrice **/
        .paymentCalculator__buttons #calculator-toggle-button.sr-button-1{
            background: #707070;
            &:hover{
                text-decoration: none;
                span{
                    text-decoration: underline;
                }
            }
        }
        .paymentCalculator__paymentsWrapper .paymentCalculator__payment .is-util,
        .calculatorColumn .icon-promo2.is-util,
        .new-customize-payment .taxesIncluded-section .payment-detail.is-light span.is-util,
        .purchase-methods-cash .purchase-detail-line .value.is-util
        {
            color: #FFFFFF;
        }
        .sr-calculator-tabs li.current{
            border-color: #FFFFFF !important;
        }
        .sr-paymentCalculator__calculatorTerm--background.selected .term{
            color: #FFFFFF !important;
            background-color: #05141F !important;
        }
        .sr-paymentCalculator__calculatorTerm--background .price span,
        .sr-paymentCalculator__calculatorTerm--background .price span.price-highlight,
        .sr-paymentCalculator__calculatorTerm--background.selected .taxesIncluded-section.is-light .payment-detail.is-light span{
            color: #05141F;
        }
        .calculator-done .sr-button-1.is-small{
            padding: 10px 75px;
            border: 2px solid #FFFFFF;
            font-size: 13px;
            &:hover{
                opacity: 1;
                text-decoration: underline;
            }
        }
        .calculatorColumn .sr-paymentCalculator-dropdown .is-focused .Select-control,
        .calculatorColumn .sr-paymentCalculator-dropdown .Select-control,
        .calculatorColumn .sr-paymentCalculator-dropdown .Select-option,
        .calculatorColumn .sr-paymentCalculator-dropdown .Select-menu-outer .Select-menu-option{
            background-color: #FFFFFF !important;
            color: #05141F !important;
        }
        .calculatorColumn .sr-paymentCalculator-dropdown .Select-input>input,
        .calculatorColumn .sr-paymentCalculator-dropdown .Select-value-label{
            color: #05141F!important;
        }
        .calculator-tradeIn .input-format-currency,
        .InputNumber--minus,
        .InputNumber--add,
        .calculator-term{
            border-radius: 0px !important;
        }
        .InputText.styled-placeholder.dark-theme{
            background-color: #FFFFFF;
        }
        .calculatorColumn .sr-link{
            color: #FFFFFF;
        }
        /*** Build and Buy ***/
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single.selected{
            color: #05141F;
        }
        .vehicle-tile-rebate-single{
            color: $labelColor;
            font-family: $fontFamily;
            icon.is-price,icon.icon-electric-vehicle{
                color: #05141F;
            }
        }
        .vehicle-note__wrapper{
            background: $bgSecondaryColor;
        }
        .VehicleDisclaimersContent .disclaimer-star,
        .VehicleDisclaimersContent,
        .VehicleDisclaimersContent p{
            color: $labelColor;
        }
        .vehicle-item .vehicle-description .vehicle-name{
            font-family: $boldFontFamily;
            font-weight: $boldFontWeight;
            color: #05141F;
            span.vehicle-body{
                font-family: $fontFamily;
                color: $labelColor;
                font-weight: normal;
            }
        }
        .vehicle-item .vehicle-description .vehicle-cost{
            div{
                .vehicle-price-label, .vehicle-price{
                    font-family: $fontFamily;
                    font-weight: normal;
                }
                .vehicle-price-label{
                    color: $labelColor;
                }
                .vehicle-price{
                    color: #05141F;
                }
            }
            div:nth-child(1){
                .vehicle-price-label, .vehicle-price{
                    font-family: $boldFontFamily;
                    font-weight: $boldFontWeight;
                    color: #05141F;
                }
            }
        }
        .vehicle-item .vehicle-payment .vehicle-payment-title{
            font-family: $fontFamily;
            color: #05141F;
        }
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-amount .vehicle-payment-amount-note{
            font-family: $fontFamily;
            color: $labelColor;
        }
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-cashdown,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-cost-credit,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-rate{
            color: $labelColor;
            font-family: $fontFamily;
        }
        .list-vehicle .vehicle-tile .sr-link,
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list li,
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single,
        .section-catalog-filter .catalog-filter__trade-in-section .order-option.dropdown.not-empty .input-label,
        .InputText.styled-placeholder>.input-control,
        .section-catalog-filter .catalog-filter-search .catalog-filter-input-search input{
            font-family: $fontFamily;
        }
        .list-vehicle .vehicle-tile .sr-link{
            color: $labelColor;
        }
        .Select.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value .Select-value-label,
        .Select.has-value.Select--single>.Select-control .Select-value .Select-value-label{
            color: #05141F;
            font-family: $fontFamily;
        }
        .section-catalog-filter .catalog-filter__trade-in-section .order-option .Select{
            .Select-menu-outer .Select-menu-option{
                color: #05141F;
                font-family: $fontFamily;
            }
        }
        .selection-year-bloc{
            box-shadow: none;
            border-radius: 0px;
            border: 1px solid #B8BBBD;
            &:hover{
                .selection-year-bloc__selectButton{
                    background-color: #37434B;
                }
                .selection-year-bloc__selectButton .sr-button-outline-2{
                    background: #05141F;
                    border-color: #05141F;
                    color: #FFFFFF;
                    &:hover{
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }
            .vehicle-rebate .vehicle-rebate-single{
                color: $labelColor;
                font-family: $fontFamily;
                icon.is-price,icon.icon-electric-vehicle{
                    color: #05141F;
                }
            }
        }
        .section-showroom-header__progressBar-stage{
            .icon-showroom, .progressBar-stage__content{
                color: $labelColor;
            }
            &.selected{
                .progressBar-stage__content{
                    color: #FFFFFF;
                }
                .progressBar-stage__active-effect{
                    background-color: #05141f !important;
                    fill: #05141f !important;
                }
            }
        }
        .section-showroom-header__model-listing-item.info-vehicle{
            span{
                span.is-util{
                    color: $labelColor;
                }
            }
        }
        .style-color__button.selected{
            border: 3px solid #05141F;
        }
        .style-trim__lists__item{
            box-shadow: none;
            border-radius: 0px;
            border: 1px solid #B8BBBD;
            font-family: $fontFamily;
            color: #05141F;
            &:hover{
                border: 1px solid #05141F;
            }
            .style-trim-btn{
                color: #05141F;
            }
            &.selected{
                border: 1px solid #05141F;
                .style-trim-btn{
                    color: #FFFFFF;
                }
            }
        }
        .bloc-options__single{
            border-radius: 0px;
            box-shadow: none;
            &--footer{
                color: #05141F;
                background-color: #F8F8F8;
                border-radius: 0px;
                .option-choose-cta{
                    font-family: $fontFamily;
                    border-radius: 0px;
                    box-shadow: none;
                    background-color: #FFFFFF;
                    color: #05141F;
                    border: 1px solid #B8BBBD;
                    icon{
                        color: #05141F;
                    }
                    &:hover{
                        border: 1px solid #05141F;
                        span{
                            text-decoration: underline;
                        }
                    }
                }
            }
            &.selected{
                background-color: #B8BBBD;
                .bloc-options__single--footer{
                    background-color: #B8BBBD;
                    .option-choose-cta{
                        font-family: $fontFamily;
                        background-color: #05141F;
                        color: #FFFFFF;
                        border: 1px solid #05141F;
                        icon{
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
        .accessory_wrapper.single{
            box-shadow: none;
            border-radius: 0px;
            border: 1px solid #B8BBBD;
            .accessory_wrapper--content{
                border-radius: 0px;
                &.has-primary-background{
                    background-color: #FFFFFF;
                }
            }
            .accessoryCode.is-light{
                color: $labelColor;
            }
            .accessoryName.sr-text{
                font-family: $boldFontFamily;
                font-weight: $boldFontWeight;
            }
            .accessory_wrapper--check icon{
                color: #05141F;
            }
        }
        .showroom-build-and-price .section-background.has-secondary-background{
            background-color: #FFFFFF;
        }
        .message-handler.warning,
        .listing-tile .listing-tile-car-save.has-warning-background{
            background-color: #05141F;
        }
        .financing-section.tile-payment-info .cashdown,
        .payment-summary-section .listing-tile-all-price{
            border-top: 4px solid #05141F;
        }
        .section-summary__content .car-equipments_summary>*{
            border: 1px solid #B8BBBD;
            border-radius: 0px;
            box-shadow: none;
        }
        .message-handler.success{
            background-color: #05141F;
        }
        .tile-tag{
            border: 1px solid #05141F;
            color: #05141F !important;
            background: #FFFFFF !important;
        }
        .vehicle-overview__summary.has-primary-background,
        .checkout__inner-content.has-primary-background{
            background-color: #F8F8F8;
        }
        .checkout__content-right-section ul.vehicle-specs li.is-light{
            color: #05141F;
        }
        .vehicle-title-specs .vehicle-title,
        .sr-sidebarsummary .sr-text-regular,
        .sr-sidebarsummary .sr-text-regular.amountLineItem,
        .SidebarSummary__groupTitle--title,
        .checkout__section-delivery .checkout__payment-options-title,
        .delivery-options-title,
        .checkout-payment-select .checkout__payment-options-list p,
        .checkout__section-delivery .checkout__payment-options-list p,
        .checkout__section-summary .checkout__payment-options-list p,
        .checkout__section-thanks .checkout__payment-options-list p,
        .checkout-payment-select .checkout__payment-options-list-separator,
        .checkout__section-delivery .checkout__payment-options-list-separator,
        .checkout__section-summary .checkout__payment-options-list-separator,
        .checkout__section-thanks .checkout__payment-options-list-separator,
        .checkout-payment-select .checkout__payment-options-subtitle--price,
        .checkout__section-delivery .checkout__payment-options-subtitle--price,
        .checkout__section-summary .checkout__payment-options-subtitle--price,
        .checkout__section-thanks .checkout__payment-options-subtitle--price,
        .FinanceSummary .SidebarSummary__accessoriesDetails .value,
        .SidebarSummary__deliveryDetails--content, .SidebarSummary__financialDetails--content,
        .SidebarSummary__deliveryDetails--item.vehicle__totalPayment,
        .SidebarSummary__financialDetails--item.vehicle__totalPayment
        {
            color: #05141F !important;
        }
        .sr-sidebarsummary .sr-text-thin{
            color: $labelColor;
        }
        .section-checkout-header__progressBar-stage{
            color: #05141F;
            border: 1px solid #B8BBBD;
            border-radius: 0px;
        }
        .section-checkout-header__progressBar-stage .picto-showroom{
            color: #05141F;
        }
        .section-checkout-header__progressBar-stage.selected{
            background-color: #05141F;
            border-color: #05141F;
            color: #FFFFFF;
            .picto-showroom{
                color: #FFFFFF;
            }
            &.validated{
                color: #FFFFFF;
            }
        }
        .section-checkout-header__progressBar-stage.validated{
            color: #05141F;
            border-color: #05141F;
        }
        .section-checkout-header__progressBar-stage:after{
            background-color: #B8BBBD;
        }
        .section-checkout-header__progressBar-stage.validated .picto-showroom:first-child{
            color: #05141F;
        }

        /*** VDP Used ***/
        .vehicleBanner-photos .vehicleBanner-photo-button{
            background: #05141F;
            .photo-available.is-util.sr-text{
                color: #FFFFFF;
            }
        }
        .vehicleBanner-personsViewed{
            color: #FFFFFF;
            border-radius: 0px;
            .amount.is-price{
                color: #FFFFFF;
            }
        }
        .vdp-used-banner-wrapper{
            border-radius: 0px;
        }
        .showroom-vdp-used-car-info .car-number.is-light,
        .showroom-vdp-used-carfax .carfax-logo p.is-light{
            color: #05141F;
        }
        .payment-summary-section .listing-tile-all-price .disclaimer-price.is-light,
        .showroom-vdp-used-certified-zone .certified-text.is-light{
            color: $labelColor !important;
        }
        .financing-section.tile-payment-info .tile-payment-amount span{
            font-style: normal;
        }
        /*** Form Trade 360 ***/
        .EstimateHeader .estimate-header--image .powered-by.is-light,
        .states-description-unit .content.is-light,
        .TaxSavings__wrapper .sr-disclaimer.is-light{
            color: #05141F;
        }

        .radio-button-cta-wrapper--single label{
            padding-top: 3px;
        }
        .officialTradeInForm .TextArea.styled-placeholder{
            height: unset;
        }
        
        input[type=checkbox]+label:before,
        input[type=checkbox]:checked+label:before{
            top: 5px;
        }
        input[type=checkbox]:checked+label:after{
            height: 8px;
            width: 8px;
            top: 4px;
        }
        /*** Forms ***/
        div .TradeIn__wrapper{
            background-color: #FFFFFF;
        }
        .estimate__content{
            background-color: #f8f8f8;
            .rc-slider{
                background: linear-gradient(to right, #05141F 0%,#05141F 100%);
            }
        }
        .TaxSavingItem__body-right .sr-subtitle-2{
            color: #05141F !important;
        }
        .confirm_tradein--icon{
            border: 1px solid #05141F;
            icon{
                color: #05141F;
            }
        }
        .range-value_column.low_value{
            background-color: #05141F !important;
        }
        .slider__content--value.low-value{
            color: #05141F !important;
        }        
        .range-value_column.high_value{
            background-color: #05141F !important;
        }
        .slider__content--value.high-value{
            color: #05141F !important;
        }
        .Select-control{
            border-radius: 0px;
            color: #05141F;
            border: 1px solid #B8BBBD;
        }
        .InputText .input-control{
            border-radius: 0px;
            border: 1px solid #B8BBBD;
            color: #05141F;
        }
        .InputAutoComplete .input-control{
            border-radius: 0px;
        }
        .InputText.styled-placeholder>.input-control{
            color: #05141F;
        }
        .field-error.InputText .input-control{
            border: 2px solid #05141F;
        }
        .InputText .errors-input{
            color: #05141F;
        }
        .forms-container{
            background-color: #f8f8f8;
            border-radius: 0px;
        }
        .checkout__inner-content.has-primary-background{
            background-color: #FFFFFF;
        }
        .checkout__inner-content{
            box-shadow: none;
            border: 1px solid #B8BBBD;
            border-radius: 0px;
        }
        .page-financing .headline1 {
            padding: 40px 0 20px;
        }
        .financing__content{
            box-shadow: none;
            border: 1px solid #B8BBBD;
        }
        .financingBlockForm.is-open+.financingBlockForm,
        .financingBlockForm:last-child{
            box-shadow: none;
        }
        .Appointment__content .appointment__steps--single.is-validated .title_wrapper>icon{
            color: #05141F;
        }

        .selection-year-bloc__model,
        .section-showroom-header__model-listing-item .info-vehicle,
        .vehicle-overview__vehicle-title .trim{
            text-transform: capitalize;
            &:first-letter{
                text-transform: capitalize;
            }
        }
        .vehicle-overview__vehicle-title .trim {
            .vehicle-model{
                text-transform: capitalize;
            }
        }
        .section-showroom-header__model-listing-item .info-vehicle{
            .is-util{
                text-transform: capitalize;
            }
        }
        .selection-year-bloc__model{
            span{
                &:nth-of-type(2){
                    text-transform: capitalize;
                }
            }
        }
        .listing-new-tile .listing-new-tile-drivePowerTrains .new-car-tagline p{
            color: #05141f!important;
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }
        .vehicle-name,
        .new-car-name,
        .vehicle-model,
        .section-showroom-header__model-listing-item .info-vehicle .is-util,
        .section-showroom-header__model-listing-item .info-vehicle:first-letter,
        .selection-year-bloc__model:first-letter,
        .selection-year-bloc__model span:nth-of-type(2),
        .vehicle-overview__vehicle-title .trim .vehicle-model,
        .vehicle-overview__vehicle-title .trim:first-letter{
            text-transform: capitalize !important;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(mobile){
    .widget-sr {
        &.makes__kia {
        .sr-topbar-login{
            padding: 0
        }
        .LoginBanner .LoginBannerWrapper {
          height: 34px;
          max-height: 34px;
          .login-content {
            display: flex;
            padding-right: 0;
            #button-login,
            #button-register {
              font-size: 12px !important;
              .label{
                font-size: 12px !important;
              }
            }
            #button-login.btn-login {
              margin-left: 5px;
            }
            #button-register.btn-register {
              margin-left: 10px;
            }
          }
        }
        .confirm_financing--wrapper{
          flex-direction: column;
        }
        .confirm_financing--title{
            font-size: 18px;
        }
        .TransactionAction--single .sr-button-outline-1:hover{
            text-decoration: underline;
        }
        .TransactionAction--single>button{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .vehicle-tile-rebate-single{
            background: $bgBtnAlpha;
            .is-price{
                color: $colorBtnAlpha;
            }
            span{
                color: $colorBtnAlpha;
            }
        }
      }
    }
  }
@include breakpoint(mobileTablet) {
    .widget-sr {
        &.makes__kia{
            .TransactionActionDetails--wrapper button,
            .TransactionActionDetails--wrapper .sr-button-outline-1{
                width: 100%;
            }
        }
    }
}
@include breakpoint(desktop) {
    .widget-sr {
        &.makes__kia{
            .TransactionActionDetails--wrapper .earnings_listing{
                margin-bottom: 20px;
            }
            .TransactionActionDetails--wrapper button{
                margin: 10px 10px 0 0;
            }
            .TransactionActionDetails--wrapper button+button{
                margin: 0;
            }
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}